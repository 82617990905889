import React, { Component } from "react";

// Site
import logo from "./logo.svg";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./App.css"; 

class App extends Component {
  apiEndpoint =
    "https://fde7wes14f.execute-api.ap-southeast-2.amazonaws.com/Dev";

  initDemo = async (e) => {
    // Load link in new window this.startTransaction();
    const response = await (await fetch(`${this.apiEndpoint}/demo`)).json()
    window.open(response.demoUrl, "_blank")
  };

  render() {
    return (
      <div className="container mx-auto">
        <div className="flex items-center justify-center mb-4">
          <img
            src={logo}
            className="w-3/12 m-auto"
            alt="Ai Motor logo"
          />
        </div>
        <div className="flex justify-center">
          <button
            className="text-center bg-teal-500 hover:bg-teal-400 text-white font-bold py-2 px-4 border-b-4 border-teal-700 hover:border-teal-500 rounded"
            onClick={this.initDemo}
          >
            Generate demo
          </button>
        </div>
        </div>
    );
  }
}

export default App;
